<template>
  <div>
    <div class="mb-4">
      <h3 class="me-1">{{ $store.state.botOrigin }}</h3>
      <div v-if="$store.state.botOrigin == 'tg_app'">
        <button
          class="btn btn-warning"
          @click="$socket.emit('get_tg_app_dialogs', {
            id: $store.state.account.id,
          })">
          Список диалогов
        </button>
      </div>
      <div v-else class="d-flex mb-4">
        <div v-if="session">
          <Auth v-if="!session.authorized" :session="session"/>
        </div>
        <button v-else class="btn btn-primary" @click="createSession">
          Создать сессию
        </button>
        <div v-if="session && session.authorized">
          <span class="badge bg-success">
            Авторизован
          </span>
        </div>
        <div class="ms-auto">
          <button
            v-if="session && session.authorized"
            class="btn btn-secondary"
            @click="checkEnableToggle">
            Парсинг чатов
          </button>
        </div>
        <div class="ms-2">
          <button
            v-if="session && session.authorized"
            class="btn btn-warning"
            @click="getDialogs">
            Список диалогов
          </button>
        </div>
        <div class="ms-2">
          <button v-if="session" class="btn btn-danger" @click="deleteSession">
            Удалить сессию
          </button>
        </div>
      </div>
    </div>

    <div
      v-for="dialog in $store.state.dialogs"
      :key="dialog.id"
      class="mb-3 d-flex">
      {{ dialog.name || dialog.id }}
      <button
        v-if="!$store.state.account.chats.filter(c => c.origin_id).map(c => c.origin_id).filter(id => dialog.id.toString().includes(id.toString())).length"
        class="btn btn-light btn-sm ms-auto"
        @click="createChatFromDialog(dialog)">
        Добавить на сайт
      </button>
      <strong v-else class="ms-auto">
        Добавлено
      </strong>
    </div>

    <h4 class="mt-4">Добавленные</h4>
    <div
      v-for="dialog in getBotOriginDialogs"
      :key="dialog.id"
      class="mb-3 d-flex">
      {{ dialog.name || dialog.id }}
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Auth from './Auth'
export default {
  props: {
    session: Object
  },
  components: {
    Auth,
  },
  data () {
    return {
      // emulatorDomain: 'https://statrend.ru',
      // emulatorDomain: 'http://localhost:5000',
      // dialogs: [],
    }
  },
  computed: {
    getBotOriginDialogs () {
      return this.$store.state.account.chats.filter(c => c.origin === this.$store.state.botOrigin)
    }
  },
  methods: {
    async deleteSession () {
      try {
        const response = await axios.post(`/e/sessions/delete`, {
          account_id: this.$store.state.account.id,
          origin: this.$store.state.botOrigin,
        })
        if (response.status === 200) {
          this.$toast.success('Сессия успешно удалена')
          this.$store.commit('mergeStore', {sessions: response.data.sessions})
        }
      } catch (e) {
        this.$toast.error(e.message)
        // console.error(e)
      }
    },

    getToken (name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },

    async createSession () {
      try {
        this.$toast.info('Запрос на создание сессии отправляется')
        const response = await axios.post(`/api/sessions`, {
          account_id: this.$store.state.account.id,
          origin: this.$store.state.botOrigin,
          phone: this.$store.state.account.phone,
        }, {
          headers: {
            'X-CSRFToken': this.getToken('csrftoken')
          }
        })
        if (response.status === 200) {
          this.$toast.success('Сессия успешно создана')
          this.$store.commit('addSession', response.data)
        }
      } catch (e) {
        this.$toast.error(e.message)
        // console.error(e)
      }
    },

    // timeout (ms) {
    //	 return new Promise(resolve => setTimeout(resolve, ms));
    // }
    // auth () {
    //	 this.$socket.emit('bot_auth', {
    //	 	id: this.$store.state.account.id,
    //	 	origin: this.$store.state.botOrigin
    //	 })
    // },

    logout () {
      console.log('logout', this.$store.state.botOrigin, this.$store.state.account.id)
    },

    async checkEnableToggle () {
      console.log('checkEnableToggle')
      try {
        const response = await axios.post(`/e/manage`, {
          account_id: this.$store.state.account.id,
          origin: this.$store.state.botOrigin,
          phone: this.$store.state.account.phone,
          action: 'check_unread',
        })
        if (response.status === 200) {
          const data = response.data
          if (data.status === 'success') {
            this.$toast.success(data.check_unread_enable ? 'Парсинг включен' : 'Парсинг выключен')
          } else {
            this.$toast.error(data.message)
          }
        }
      } catch (e) {
        this.$toast.error(e)
      }
    },
    async getDialogs () {
      console.log('getDialogs', this.$store.state.botOrigin, this.$store.state.account.id)
      try {
        this.$toast.success('Запрос на получение списка диалогов отправлен')
        const response = await axios.post(`/e/manage`, {
          account_id: this.$store.state.account.id,
          origin: this.$store.state.botOrigin,
          phone: this.$store.state.account.phone,
          action: 'dialogs',
        })
        if (response.status === 200) {
          const data = response.data
          if (data.status === 'error') {
            this.$toast.error(data.message)
          } else if (data.status === 'success' && data.data) {
            this.$store.commit('mergeStore', {dialogs: data.data})
            // this.dialogs = data.data
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    createChatFromDialog (dialog) {
      console.log('createChatFromDialog', dialog)
      this.$socket.emit('chats_from_dialog', {
        account_id: this.$store.state.account.id,
        name: dialog.name,
        // origin_id: dialog.id,
        origin_id: dialog.id,
        origin: this.$store.state.botOrigin,
      })
    },
  },
  async mounted () {
    try {
      // setTimeout(() => {
      //	 this.auth()
      // }, 10000)
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
