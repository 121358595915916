<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Боты
        </li>
      </ol>
    </nav>
    <div class="d-flex mb-3">
      <h3>Боты</h3>
      <button
        class="btn btn-light ms-auto"
        data-bs-toggle="modal"
        data-bs-target="#accountModal"
        @click="$store.commit('mergeStore', {account: {}})">
        Добавить
      </button>
    </div>

    <div class="d-flex flex-wrap">
      <div
        v-for="account in $store.state.bots"
        :key="account.id"
        class="card card-body me-3 mb-3">
        <div class="d-flex align-items-center mb-2">
          <h4 class="card-title mb-0">
            {{ account.first_name }} {{ account.last_name }}
          </h4>
          <p class="card-text mx-2 text-black-50">
            {{ account.username }}
            {{ account.phone }}
          </p>
        </div>
        <div class="">

          <button
            @click="$store.commit('mergeStore', {
              account, 
              dialogs: [], 
              botOrigin: 'tg_app'
            }); $socket.emit('get_bot', {id: account.id, origin: 'tg_app'})"
            data-bs-toggle="modal"
            data-bs-target="#accountModal"
            class="btn btn-sm mx-1"
            :class="{
              'btn-tg': account.tg_app.authorized,
              'btn-secondary': !account.tg_app.authorized
            }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telegram" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
            </svg>
            <span class="text-white mx-1">+</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-phone" viewBox="0 0 16 16">
              <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
          </button>

          <button
            @click="$store.commit('mergeStore', {account, dialogs: [], botOrigin: 'tg_web'}); $socket.emit('get_bot', {id: account.id, origin: 'tg_web'})"
            data-bs-toggle="modal"
            data-bs-target="#accountModal"
            class="btn btn-sm mx-1 btn-secondary"
            :class="{
              'btn-tg': sessionActive(account.id, 'tg_web'),
            }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telegram" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
            </svg>
            <span class="text-white mx-1">+</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-browser-chrome" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
            </svg>
          </button>

          <button
            class="btn btn-sm mx-1 btn-secondary"
            @click="$store.commit('mergeStore', {account, dialogs: [], botOrigin: 'wa_web'}); $socket.emit('get_bot', {id: account.id, origin: 'wa_web'})"
            data-bs-toggle="modal"
            data-bs-target="#accountModal"
            :class="{
              'btn-wa': sessionActive(account.id, 'wa_web'),
            }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-whatsapp" viewBox="0 0 16 16">
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
            </svg>
          </button>

          <button
            class="btn btn-sm mx-1 btn-secondary"
            @click="$store.commit('mergeStore', {account, dialogs: [], botOrigin: 'vk_web'}); $socket.emit('get_bot', {id: account.id, origin: 'vk_web'})"
            data-bs-toggle="modal"
            data-bs-target="#accountModal"
            :class="{
              'btn-vk': sessionActive(account.id, 'vk_web'),
            }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
              <!-- <path fill="#1876d2" d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z"/> -->
              <path fill="#fff" d="M25.2,33.2c-9,0-14.1-6.1-14.3-16.4h4.5c0.1,7.5,3.5,10.7,6.1,11.3V16.8h4.2v6.5c2.6-0.3,5.3-3.2,6.2-6.5h4.2	c-0.7,4-3.7,7-5.8,8.2c2.1,1,5.5,3.6,6.7,8.2h-4.7c-1-3.1-3.5-5.5-6.8-5.9v5.9H25.2z"/>
            </svg>
          </button>

        </div>
        <!-- <div class="position-absolute top-0 end-0 badge bg-warning text-dark fs-12">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-robot" viewBox="0 0 16 16">
            <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z"/>
            <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z"/>
          </svg>
        </div> -->
      </div>
    </div>

    <div
      class="modal fade"
      id="accountModal"
      tabindex="-1"
      aria-labelledby="titleAccountModal"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="titlePostModal">
              Бот
              {{ $store.state.account.first_name }}
              {{ $store.state.account.last_name }}
              {{ $store.state.account.username }}
              {{ $store.state.account.phone }}
            </h5>
            <button
              v-if="$store.state.account.id"
              @click="deleteAccount" 
              class="btn btn-danger btn-sm ms-3">
              Удалить
            </button>
            
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="$store.state.account.id" class="">

              <div class="card mb-4">
                <div class="card-header">
                  Настройка периодичности парсинга
                </div>
                <div class="card-body d-flex justify-content-center">
                  <div class="me-2">
                    <input
                      type="time"
                      v-model="$store.state.account.parse_period"
                      class="form-control">
                  </div>
                  <button
                    @click="$socket.emit('accounts', {
                      id: $store.state.account.id,
                      parse_period: $store.state.account.parse_period
                    })" 
                    class="btn btn-primary">
                    Сохранить
                  </button>
                </div>
              </div>

              <Emulator :session="getBotSession"/>

            </div>
            <div v-else class="mb-3">
              <label class="form-label">Номер телефона</label>
              <input
                type="text"
                v-model="$store.state.account.phone"
                class="form-control"
                placeholder="79008007060">
            </div>
            <!-- <div v-if="!$store.state.account.id" class="mb-3">
              <label class="form-label">Пароль</label>
              <input
                type="password"
                :readonly="code_sended"
                v-model="$store.state.account.password"
                class="form-control"
                placeholder="*******">
            </div> -->
            <!-- <div v-if="code_sended" class="mb-3">
              <label class="form-label">Код</label>
              <input
                type="text"
                v-model="$store.state.account.code"
                class="form-control">
            </div> -->
          </div>
          <div class="modal-footer">
            <!-- <button v-if="!$store.state.account.id && !code_sended" class="btn btn-danger me-2" @click="$socket.emit('send_code_request', $store.state.account)">Отправить код</button> -->
            <button
              v-if="!$store.state.account.id"
              class="btn btn-primary me-2"
              @click="$socket.emit('accounts', $store.state.account)">Сохранить</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
moment.locale('ru')
import Emulator from './components/Emulator'

export default {
  name: 'Bots',
  data () {
    return {
      // code_sended: false
      // emulatorDomain: 'http://localhost:5000',
      sessions: []
    }
  },
  components: {
    Emulator,
  },
  sockets: {
    bot: function (data) {
      console.log('bot', data)
      this.$toast.success('Бот')
      const bots = [...this.$store.state.bots]
      const bot = bots.filter(b => b.id === data.id)[0]
      if (bot) {
        const config = bot[data.messenger]
        config.status = data.status
        config.authorized = data.authorized
      }
      // this.$store.commit('mergeStore', bots)
    },
  },
  computed: {
    getBotDialogs () {
      return this.$store.state.dialogs.filter(d => d.origin === this.$store.state.botOrigin)
    },
    getBotSession () {
      console.log('getBotSession', this.$store.state.sessions)
      return this.$store.state.sessions.filter(s => s.id === this.$store.state.account.id && s.origin === this.$store.state.botOrigin)[0]
    },    
  },
  methods: {
    deleteAccount () {
      if (confirm('Удалить аккаунт?')) {
        this.$socket.emit('delete_account', {
          id: this.$store.state.account.id,
        })
      }
    },

    sessionActive (account_id, origin) {
      const session = this.$store.state.sessions.filter(s => s.id === account_id && s.origin === origin)[0]
      if (session && session.authorized) {
        return true
      } 
      return false
    },
    async getSessions () {
      try {
        const response = await axios.get(`/api/sessions`)
        console.log('response.status', response.status, response)
        if (response.status === 200) {
          this.$store.commit('mergeStore', {sessions: response.data.sessions})
        }
      } catch (e) {
        if (e.name === 'Error') {
          this.$toast.error('Проблемы на сервере с эмуляторами')
        } else {
          this.$toast.error(e.message)
        }
      }
    },
  },
  async mounted () {
    try {
      this.$socket.emit('get_data', ['bots'])
      await this.getSessions()
    } catch (e) {
      // console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  .btn-group{
    visibility: hidden;
  }
  &:hover{
    .btn-group{
      visibility: visible;
    }
  }
}

.btn-wa, .btn-wa:hover{
  background-color: rgb(65, 234, 75);
  border-color: rgb(65, 234, 75);
}
.btn-vk, .btn-vk:hover{
  background-color: #1876d2;
  border-color: #1876d2;
}
.btn-tg, .btn-tg:hover{
  background-color: rgb(54, 169, 244);
  border-color: rgb(54, 169, 244);
}
</style>
