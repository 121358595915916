<template>
	<div>Авторизация с помощью qr-кода</div>
	<img v-if="qr" :src="'data:image/png;base64, ' + qr">
</template>

<script>
import axios from 'axios';
export default {
	props: {
		session: Object
	},
	data () {
		return {
			qr: null,
			interval: null,
		}
	},
	methods: {

		async authRequest () {
			try {
				const response = await axios.post(`/api/manage `, {
					id: this.$store.state.account.id,
					origin: this.$store.state.botOrigin,
					phone: this.$store.state.account.phone,
					action: 'auth',
				})
				if (response.status === 200) {
					const data = response.data
					if (data.qr) {
						this.qr = data.qr
					}
					this.$store.commit('updateSession', data)
					if (data.authorized) {
						clearInterval(self.interval)
					}
				}
			} catch (e) {
				console.error(e)
			}
		},
	},
	async mounted () {
		try {
			self.interval = setInterval(() => {
				this.authRequest()
			}, 5000)
		} catch (e) {
			console.error(e)
		}
	}
}
</script>